import React from 'react'
import { graphql } from 'gatsby'

import Layout from "../components/layout/layout"

import Seo from "../components/layout/seo";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


export const BlogTemplate = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicBlog.nodes[0].data

  return (
    <Layout showBottom="false">
      <Seo title="Drops | Blog | Toothpaste Drops" path="/blog" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <div style={{ minHeight: 'calc(100vh - 355px)' }}>
        <div style={{ paddingTop: '100px'}} className="container mb-5">
          {document.body.map((item, key) => (
            <>
            {item.slice_type === "header" ? (
              <div key={key}>
                <div dangerouslySetInnerHTML={{__html:item.primary.headline_1.html}} />
                <img className="w-100 h-auto mb-3" src={item.items[0].header_bild.url} alt={item.items[0].header_bild.alt} />
                <div dangerouslySetInnerHTML={{__html:item.items[0].zusammenfassung.html}} />
              </div>
            ) : (
              <Row className={`mt-5`} key={key}>
                {item.items[0].optional_image.url !== null ? (
                  <>
                    <Col md={5} className="align-self-center mb-3">
                      <img className="w-100 h-auto" src={item.items[0].optional_image.url} alt={item.items[0].optional_image.alt} />
                    </Col>
                    <Col md={7} className="align-self-center">
                      <div dangerouslySetInnerHTML={{__html:item.primary.h2_headline.html}} />
                      <div dangerouslySetInnerHTML={{__html:item.items[0].section_text.html}} />
                    </Col>
                  </>
                ) : (
                  <>
                    <div dangerouslySetInnerHTML={{__html:item.primary.h2_headline.html}} />
                    <div dangerouslySetInnerHTML={{__html:item.items[0].section_text.html}} />
                  </>
                )}
              </Row>
            )}
            </>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($uid: String) {
    allPrismicBlog(filter: { uid: { eq: $uid } }) {
      nodes {
        data {
          body {
            ... on PrismicBlogDataBodyHeader {
              id
              slice_type
              primary {
                headline_1 {
                  html
                }
              }
              items {
                header_bild {
                  url
                  alt
                }
                zusammenfassung {
                  html
                }
              }
            }
            ... on PrismicBlogDataBodySection {
              id
              slice_type
              primary {
                h2_headline {
                  html
                }
              }
              items {
                optional_image {
                  url
                  alt
                }
                image_position
                section_text {
                  html
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`

export default BlogTemplate
